import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

import { snakeCase } from "lodash";
import { ConditionsWrapperType } from "../../logic/types/types";
import UserNameMinLen from "./conditions/UserNameMinLen";
import UserNameConditionsWrapper from "./UserNameConditionsWrapper.vue";

const condition: EntityData = {
  'setupView': UserNameConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.UserNameConditionsWrapper,
    'currentConditionType': UserNameMinLen.structure.type,
    'currentCondition': UserNameMinLen.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.UserNameConditionsWrapper) }`
}

export default condition
