import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

import { snakeCase } from "lodash";
import { ConditionsWrapperType } from "../../logic/types/types";
import MessageTextRegExp from "./conditions/MessageTextRegExp";
import RegexpConditionsWrapper from "./RegexpConditionsWrapper.vue";

const condition: EntityData = {
  'setupView': RegexpConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.RegexpConditionsWrapper,
    'currentConditionType': MessageTextRegExp.structure.type,
    'currentCondition': MessageTextRegExp.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.RegexpConditionsWrapper) }`
}

export default condition
