import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { snakeCase } from "lodash";
import ChangeTriggerActiveStateActionSetupView
  from "@/components/TriggerSetup/actions/ChangeTriggerActiveStateAction/ChangeTriggerActiveStateActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";

const action: EntityData = {
  'setupView': ChangeTriggerActiveStateActionSetupView,
  'structure': {
    'type': ActionsType.ChangeTriggerActiveStateAction,
    'triggerKey': null,
    'state'     : false,
    'chance'    : 100,
  },
  'titleKey': `trigger_action_${ snakeCase(ActionsType.ChangeTriggerActiveStateAction) }`
}

export default action
