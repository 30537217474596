import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { ConditionsType, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import FirstUserActivityLowerThatSetupView
  from "@/components/TriggerSetup/conditions/FirstUserActivityConditionsWrapper/conditions/FirstUserActivityLowerThat/FirstUserActivityLowerThatSetupView.vue";
import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': FirstUserActivityLowerThatSetupView,
  'structure': {
    'type': ConditionsType.FirstUserActivityLowerThat,
    ...baseCondition,
  } as TriggerCondition,
  'titleKey': `trigger_condition_${ snakeCase(ConditionsType.FirstUserActivityLowerThat) }`
}

export default condition
