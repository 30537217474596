import { render, staticRenderFns } from "./FirstUserMessageTimeConditionsWrapper.vue?vue&type=template&id=54c88ea1&scoped=true&"
import script from "./FirstUserMessageTimeConditionsWrapper.vue?vue&type=script&lang=ts&"
export * from "./FirstUserMessageTimeConditionsWrapper.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54c88ea1",
  null
  
)

export default component.exports