import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { snakeCase } from "lodash";
import SendFormResultToTargetSetupView
  from "@/components/TriggerSetup/actions/SendFormResultToTarget/SendFormResultToTargetSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";

const action: EntityData = {
  'setupView': SendFormResultToTargetSetupView,
  'structure': {
    'type': ActionsType.SendFormResultToTarget,
    'text': [ {
      "text": "",
      "buttons": [],
      "remove_after": 0,
      "send_after": 0,
      "pin": false,
      "disable_link_preview": false,
      "disable_notify": false,
      "remove_previous": false
    } ],
    'target': null,
    'chance': 100,
  },
  'titleKey': `trigger_action_${ snakeCase(ActionsType.SendFormResultToTarget) }`
}

export default action
