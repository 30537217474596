






import TriggerValidationMixin from '@/components/TriggerSetup/components/TriggerAdvancedValidate/TriggerValidationMixin'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component
export default class TriggerAdvancedValidateMixin extends Mixins<TriggerValidationMixin>(TriggerValidationMixin) {
  @VModel() model !:any

  get invalidTriggerField():string | null {
    return this.firstInvalidTriggerField(this.validTrigger)
  }

  get validTrigger(): Record<string, boolean> {
    return {
      trigger_validate_actions: this.trigger_validate_actions,
      trigger_validate_actions_values: this.trigger_validate_actions_values,
    }
  }

  get trigger_validate_actions():boolean {
    if (this.model.length > 0) {
      return this.hasActionGroups(this.model)
    }

    return true
  }

  get trigger_validate_actions_values():boolean {
    if (this.model.length > 0) {
      return this.actionValuesValid(this.model, this.checkAdvancedActions)
    }

    return true
  }

  checkAdvancedActions(action: any):boolean {
    const sendActions: Array<string> = [
      'SendMessageAction',
      'SendFormToChat',
      'SendFormResultToTarget'
    ]

    const sendToPrivateActions: Array<string> = [
      'SendPrivateMessageAction',
      'SendFormToPrivate'
    ]

    const infusionValidActions: Array<string> = [
      'KickAction',
      'DeleteMessageAction',
      'BanAction',
      'UnbanAction',
      'UnmuteAction',
      'ChangeReputationIntervalAction',
      'SetReputationAction',
      'ChangePointsIntervalAction',
      'SetPointsAction',
      'ChangeXpIntervalAction',
      'WarnAction',
      'WarnActionsWrapper',
      'TriggerWarnAction'
    ]

    const forwardMessageToTargetActions: Array<string> = [
      'ForwardReplyMessageToTargetAction',
      'ForwardMessageToTargetAction'
    ]

    let isInvalid = false
    if (sendActions.includes(action.type)) {
      isInvalid = this.checkTextAction(action.text)
    } else if (sendToPrivateActions.includes(action.type)) {
      if(this.checkTextAction(action.text) && action.users.length !== 0) {
        isInvalid = true
      }
    } else if (action.type === 'SendMessageToTargetAction') {
      if (action.target !== null) {
        isInvalid = this.checkTextAction(action.text)
      }
    } else if (forwardMessageToTargetActions.includes(action.type)) {
      if (action.target !== null) {
        isInvalid = true
      }
    } else if (action.type === 'SendMessageToCalculatedTargetAction') {
      if (this.checkTextAction(action.text) && this.checkTextAction(action.failedText)) {
        isInvalid = true
      }
    } else if (action.type === 'MuteAction') {
      isInvalid = true
    } else if (action.type === 'ChangeTriggerActiveStateAction') {
      if(action.triggerKey !== null) {
        isInvalid = true
      }
    } else if (infusionValidActions.includes(action.type)) {
      isInvalid = true
    }
    return isInvalid
  }

}
