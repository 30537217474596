


























import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import UserInformationSetupView from './conditions/UserInformation'
import ReplyUserInformationSetupView from './conditions/ReplyUserInformation'
import { conditionsWrappers } from '@/components/TriggerSetup/conditions'

import { cloneDeep, snakeCase } from 'lodash'
import { Component, Mixins } from 'vue-property-decorator'
import { ConditionsType } from '@/components/TriggerSetup/logic/types/types'
import { SelectOption } from 'piramis-base-components/src/logic/types'

@Component({
  data() {
    return {
      snakeCase
    }
  }
})
export default class UserInformationConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView) {

  get userInformationConditionOptions():Array<SelectOption> {
    return conditionsWrappers.UserInformationWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === ConditionsType.UserInformation) {
      return UserInformationSetupView
    } else if (this.conditionData.currentConditionType === ConditionsType.ReplyUserInformation) {
      return ReplyUserInformationSetupView
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType){
        this.conditionData.currentCondition = cloneDeep(this.viewByConditionType.structure)
      }
    })
  }

}
