


























import { conditionsWrappers } from '@/components/TriggerSetup/conditions'
import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import { ConditionsType } from '../../logic/types/types'
import MessageTextFullMatch from './conditions/MessageTextFullMatch'
import MessageTextEndWith from './conditions/MessageTextEndWith'
import MessageTextStartWith from './conditions/MessageTextStartWith'
import MessageTextSubstringMatch from '../MessageTextSubstringMatch'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'

@Component
export default class MessageTextConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
  get messageTextConditionOptions():Array<SelectOption> {
    return conditionsWrappers.MessageTextConditionsWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === ConditionsType.MessageTextFullMatch) {
      return MessageTextFullMatch
    } else if (this.conditionData.currentConditionType === ConditionsType.MessageTextEndWith) {
      return MessageTextEndWith
    } else if (this.conditionData.currentConditionType === ConditionsType.MessageTextStartWith) {
      return MessageTextStartWith
    } else if (this.conditionData.currentConditionType === ConditionsType.MessageTextSubstringMatch) {
      return MessageTextSubstringMatch
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType){
        this.conditionData.currentCondition = cloneDeep(this.viewByConditionType.structure)
      }
    })
  }
}
