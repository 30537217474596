


























import { conditionsWrappers } from '@/components/TriggerSetup/conditions'
import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import MessageTextMaxLen from './conditions/MessageTextMaxLen'
import MessageTextMinLen from './conditions/MessageTextMinLen'
import ReplyMessageTextMaxLen from './conditions/ReplyMessageTextMaxLen'
import ReplyMessageTextMinLen from './conditions/ReplyMessageTextMinLen'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'

@Component
export default class MessageTextLenConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
  get daysWithMessagesConditionOptions():Array<SelectOption> {
    return conditionsWrappers.MessageTextLenConditionsWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === 'MessageTextMaxLen') {
      return MessageTextMaxLen
    } else if (this.conditionData.currentConditionType === 'MessageTextMinLen') {
      return MessageTextMinLen
    } else if (this.conditionData.currentConditionType === 'ReplyMessageTextMaxLen') {
      return ReplyMessageTextMaxLen
    } else if (this.conditionData.currentConditionType === 'ReplyMessageTextMinLen') {
      return ReplyMessageTextMinLen
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType){
        this.conditionData.currentCondition = cloneDeep(this.viewByConditionType.structure)
      }
    })
  }
}
