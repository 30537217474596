import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { ConditionsType, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { snakeCase } from "lodash";
import FirstUserEnterTimeMoreThatSetupView
  from "@/components/TriggerSetup/conditions/FirstUserEnterTimeConditionsWrapper/conditions/FirstUserEnterTimeMoreThat/FirstUserEnterTimeMoreThatSetupView.vue";

const condition: EntityData = {
  'setupView': FirstUserEnterTimeMoreThatSetupView,
  'structure': {
    'type': ConditionsType.FirstUserEnterTimeMoreThat,
    ...baseCondition,
  } as TriggerCondition,
  'titleKey': `trigger_condition_${ snakeCase(ConditionsType.FirstUserEnterTimeMoreThat) }`
}

export default condition
