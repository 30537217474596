import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import FirstUserMessageTimeConditionsWrapper from "./FirstUserEnterTimeConditionsWrapper.vue";
import FirstUserEnterTimeMoreThat from "./conditions/FirstUserEnterTimeMoreThat";

import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': FirstUserMessageTimeConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.FirstUserEnterTimeConditionsWrapper,
    'currentConditionType': FirstUserEnterTimeMoreThat.structure.type,
    'currentCondition': FirstUserEnterTimeMoreThat.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.FirstUserEnterTimeConditionsWrapper) }`
}

export default condition
