import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import ReputationEquals from "./conditions/ReputationEquals";
import ReputationConditionsWrapper from "./ReputationConditionsWrapper.vue";

import { snakeCase } from "lodash";
import store from "@/store/store";

const condition: EntityData = {
  'setupView': ReputationConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.ReputationConditionsWrapper,
    'currentConditionType': ReputationEquals.structure.type,
    'currentCondition': ReputationEquals.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.ReputationConditionsWrapper) }`,
  limited: () => {
    if (store.getters.isChatSet && store.getters.isChatLicenseExists && store.getters.isChatAtLeastAdvanced) {
      return null
    } else {
      return [ {
        color: store.state.themeConfig.config.tariffs?.advanced.color,
        text: 'Advanced'
      } ]
    }
  }
}

export default condition
