var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"send-form-result-to-target"},[_c('text-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        'model': this,
        disabled: _vm.disabled,
        'key': 'forwardTarget',
        'prefix': 'trigger_action_receive_',
        'validation': 'required',
      }
    }}}),_c('action-text',{attrs:{"disabled":_vm.disabled,"placeholders":_vm.FORM_PLACEHOLDERS},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }