import {
  ActionsType,
  ActionsWrapperType,
  ConditionsWrapperType,
  IterableListItem,
  RawTriggerData,
  TriggerCondition,
  TriggerData
} from "@/components/TriggerSetup/logic/types/types";
import { IterableItemFactory } from "@/components/TriggerSetup/logic/factories/iterable-item-factory";
import ConditionHelper, { conditionsWrappers } from "@/components/TriggerSetup/conditions";
import ActionsHelper, { actionsWrappers } from "@/components/TriggerSetup/actions";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

import { cloneDeep } from 'lodash'

export class TriggerFactory {

  conditionHelper = new ConditionHelper()

  actionHelper = new ActionsHelper()

  iterableItemFactory: IterableItemFactory = new IterableItemFactory()

  getTriggerTemplate(): RawTriggerData {
    return {
      type: "trigger",
      name: "",
      chat_id: null,
      actionExecuteType: "Random",
      chance: 100,
      delay: 0,
      from: null,
      to: null,
      limit: 0,
      limitInterval: 0,
      globalLimit: 0,
      globalLimitInterval: 0,
      warnLimit: 0,
      warnLimitInterval: 0,
      conditionCheckInterval: 0,
      processEditedMessages: false,
      processEditedMessageOnes: true,
      continueProcessAfterSuccessEdit: false,
      roundLimitInterval: false,
      roundGlobalLimitInterval: false,
      roundWarnLimitInterval: false,
      transparent: false,
      shared: false,
      multiplyForm: false,
      processAlbumsLikeFirstPhoto: true,
      form: "",
      conditions: [ [] ],
      actions: [ this.actionGroupTemplate ],
      conditionSuccessActions: [],
      conditionFailActions: [],
      limitActions: [],
      globalLimitActions: [],
      warnActions: [],
      formActions: []
    };
  }

  get actionGroupTemplate(): any {
    return {
      from: 0,
      to: 0,
      chance: 100,
      actions: [],
    }
  }

  createConditionsGroup(value?: Array<IterableListItem<TriggerCondition>>): IterableListItem<Array<IterableListItem<TriggerCondition>>> {
    return this.iterableItemFactory.create<Array<IterableListItem<TriggerCondition>>>(value || [])
  }

  createCondition(value: any): IterableListItem<any> {
    const valueCopy = cloneDeep(value)

    if (value.structure === undefined) {
      let condition: EntityData;

      if (conditionsWrappers.RegexpConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.RegexpConditionsWrapper]);
      } else if (conditionsWrappers.XpConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.XpConditionsWrapper]);
      } else if (conditionsWrappers.MessageCountConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.MessageCountConditionsWrapper]);
      } else if (conditionsWrappers.DaysWithMessagesConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.DaysWithMessagesConditionsWrapper]);
      } else if (conditionsWrappers.MessageTextLenConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.MessageTextLenConditionsWrapper]);
      } else if (conditionsWrappers.MessageTextConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.MessageTextConditionsWrapper]);
      } else if (conditionsWrappers.MessageWordConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.MessageWordConditionsWrapper]);
      } else if (conditionsWrappers.UserLoginConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.UserLoginConditionsWrapper]);
      } else if (conditionsWrappers.UserNameConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.UserNameConditionsWrapper]);
      } else if (conditionsWrappers.RankConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.RankConditionsWrapper]);
      } else if (conditionsWrappers.ActionPointsConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.ActionPointsConditionsWrapper]);
      } else if (conditionsWrappers.ReplyActionPointsConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.ReplyActionPointsConditionsWrapper]);
      } else if (conditionsWrappers.ReputationConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.ReputationConditionsWrapper]);
      } else if (conditionsWrappers.ReplyReputationConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.ReplyReputationConditionsWrapper]);
      } else if (conditionsWrappers.FirstUserMessageTimeConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.FirstUserMessageTimeConditionsWrapper]);
      } else if (conditionsWrappers.FirstUserEnterTimeConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.FirstUserEnterTimeConditionsWrapper]);
      } else if (conditionsWrappers.LastUserEnterTimeConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.LastUserEnterTimeConditionsWrapper]);
      } else if (conditionsWrappers.FirstUserActivityConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.FirstUserActivityConditionsWrapper]);
      } else if (conditionsWrappers.ReplyMessageWordConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.ReplyMessageWordConditionsWrapper]);
      } else if (conditionsWrappers.ReplyMessageTextConditionsWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.ReplyMessageTextConditionsWrapper]);
      } else if (conditionsWrappers.UserInformationWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.UserInformationWrapper]);
      } else if (conditionsWrappers.CheckBindedChannelWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.CheckBindedChannelWrapper]);
      } else if (conditionsWrappers.CheckBoostWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.CheckBoostWrapper]);
      } else if (conditionsWrappers.CheckPremiumWrapper.includes(valueCopy.type)) {
        condition = cloneDeep(this.conditionHelper.conditions[ConditionsWrapperType.CheckPremiumWrapper]);
      } else {
        condition = cloneDeep(this.conditionHelper.conditions[value.type]);
        condition.structure = value
      }

      if (condition !== undefined && Object.values(conditionsWrappers).flat().includes(valueCopy.type)) {
        condition.structure.currentConditionType = valueCopy.type
        condition.structure.currentCondition = valueCopy
      }

      return this.iterableItemFactory.create(condition)
    }

    return this.iterableItemFactory.create(cloneDeep(value))
  }

  createActionGroup(value?: any): any {
    return this.iterableItemFactory.create<any>({ ...this.actionGroupTemplate, ...value } || this.actionGroupTemplate)
  }

  createAction(value: any): IterableListItem<any> {
    const valueCopy = cloneDeep(value)

    if (value.structure === undefined) {
      let action: EntityData;

      if (actionsWrappers.WarnActionsWrapper.includes(valueCopy.type)) {
        action = cloneDeep(this.actionHelper.actions[ActionsWrapperType.WarnActionsWrapper]);

        action.structure.sendWarnMessage = valueCopy.type === ActionsType.WarnAction
        action.structure.currentAction = valueCopy
      } else {
        action = cloneDeep(this.actionHelper.actions[value.type]);
        action.structure = value
      }

      return this.iterableItemFactory.create(action)
    }

    return this.iterableItemFactory.create(valueCopy)
  }

  processRawTriggerDataForFrontend(rawTriggerData: RawTriggerData): TriggerData {
    const rawTriggerDataCopy: RawTriggerData = cloneDeep(rawTriggerData)

    const conditions: Array<IterableListItem<Array<IterableListItem<TriggerCondition>>>> =
        rawTriggerDataCopy.conditions
            .map((conditionGroup: Array<TriggerCondition>) =>
                (this.createConditionsGroup(conditionGroup.map((condition: TriggerCondition) => this.createCondition(condition)))))

    const actions: { [key: string]: any } = {}
    const actionsKeys: Array<string> = [ 'actions', 'conditionSuccessActions', 'conditionFailActions', 'limitActions', 'globalLimitActions', 'warnActions', 'formActions' ]

    actionsKeys.forEach((key: string) => {
      if ((rawTriggerDataCopy as { [key: string]: any })[key]) {
        actions[key] = ((rawTriggerDataCopy as { [key: string]: any })[key] as Array<any>)
            .map((actionGroup: any) => {
              actionGroup.actions = actionGroup.actions.map((action: any) => this.createAction(action))
              return this.createActionGroup(actionGroup)
            })
      } else {
        actions[key] = []
      }
    })

    return { ...rawTriggerDataCopy, conditions, ...actions } as any as TriggerData
  }

  triggerDataToRawTriggerData(triggerData: TriggerData): RawTriggerData {
    const triggerDataCopy: TriggerData = cloneDeep(triggerData);
    const getValues = (item: Array<IterableListItem<any>>, type?: string): any => {

      if (type === 'conditions') {
        return this.processConditions(item)
      } else {
        const listWithValuesOnly = item.map((child) => {
          if (child.value.actions) {
            return child.value
          }
        })

        if (listWithValuesOnly.length) {
          return listWithValuesOnly.map((subChild: any) => {
            if (subChild && subChild.actions) {

              subChild.actions = subChild.actions.map((action: any) => {
                if (action.value.structure.type === ActionsWrapperType.WarnActionsWrapper) {
                  return action.value.structure.currentAction
                }

                return action.value.structure
              })

              return subChild
            }
          })
        }
      }
    }

    return {
      ...triggerDataCopy,
      actions: getValues(triggerDataCopy.actions, 'actions'),
      conditions: getValues(triggerDataCopy.conditions, 'conditions'),
      conditionSuccessActions: getValues(triggerDataCopy.conditionSuccessActions, 'conditionSuccessActions'),
      conditionFailActions: getValues(triggerDataCopy.conditionFailActions, 'conditionFailActions'),
      limitActions: getValues(triggerDataCopy.limitActions, 'limitActions'),
      globalLimitActions: getValues(triggerDataCopy.globalLimitActions, 'globalLimitActions'),
      warnActions: getValues(triggerDataCopy.warnActions, 'warnActions'),
      formActions: getValues(triggerDataCopy.formActions, 'formActions'),
    } as unknown as RawTriggerData
  }

  processConditions(item: Array<IterableListItem<any>>) {
    return item.map((child) => {
      return child.value.map((subChild:any) => {
        if (Object.keys(conditionsWrappers).includes(subChild.value.structure.type)) {
          return subChild.value.structure.currentCondition
        } else {
          return subChild.value.structure
        }
      })
    })

  }

  createNewTrigger({ chatId }: { chatId: number }): TriggerData {
    const triggerData: RawTriggerData = cloneDeep(this.getTriggerTemplate())
    triggerData.shared = true
    triggerData.chat_id = chatId
    return this.processRawTriggerDataForFrontend(triggerData)
  }

  createNewTriggerFromExists(triggerData: RawTriggerData): TriggerData {
    triggerData = cloneDeep(triggerData)
    delete triggerData.id;
    delete triggerData.owner;
    delete triggerData.owner_id;

    return this.processRawTriggerDataForFrontend({ ...this.getTriggerTemplate(), ...triggerData })
  }
}
