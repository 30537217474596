import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { snakeCase } from "lodash";
import ChangeXpIntervalActionSetupView
  from "@/components/TriggerSetup/actions/ChangeXpIntervalAction/ChangeXpIntervalActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";

const action: EntityData = {
  'setupView': ChangeXpIntervalActionSetupView,
  'structure': {
    'type': ActionsType.ChangeXpIntervalAction,
    'target': "Caller",
    // count : 0, // can be positive and negative
    'from'  : 0, // can be positive and negative
    'to'    : 0,
    'chance': 100,
  },
  'titleKey': `trigger_action_${ snakeCase(ActionsType.ChangeXpIntervalAction) }`
}

export default action
