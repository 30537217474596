import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

import { snakeCase } from "lodash";
import { ConditionsWrapperType } from "../../logic/types/types";
import MessageTextMaxLen from "./conditions/MessageTextMaxLen";
import MessageTextLenConditionsWrapper from "./MessageTextLenConditionsWrapper.vue";

const condition: EntityData = {
  'setupView': MessageTextLenConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.MessageTextLenConditionsWrapper,
    'currentConditionType': MessageTextMaxLen.structure.type,
    'currentCondition': MessageTextMaxLen.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.MessageTextLenConditionsWrapper) }`
}

export default condition
