import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { snakeCase } from "lodash";
import ForwardMessageToTargetActionSetupView
  from "@/components/TriggerSetup/actions/ForwardMessageToTargetAction/ForwardMessageToTargetActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";

const action: EntityData = {
  'setupView': ForwardMessageToTargetActionSetupView,
  'structure': {
    'type': ActionsType.ForwardMessageToTargetAction,
    'target': null,
    'chance': 100,
  },
  'titleKey': `trigger_action_${ snakeCase(ActionsType.ForwardMessageToTargetAction) }`
}

export default action
