import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import CheckBoostConditionsWrapper from "./CheckBoostConditionsWrapper.vue";
import CheckBoost from "./conditions/CheckBoost";

import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': CheckBoostConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.CheckBoostWrapper,
    'currentConditionType': CheckBoost.structure.type,
    'currentCondition': CheckBoost.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.CheckBoostWrapper) }`,
  // limited: () => {
  //   if (!store.getters.isChatLicenseExists || !store.getters.getHaveBrand || !store.getters.getHaveLicense) {
  //     return BRAND_TAG
  //   } else {
  //     return null
  //   }
  // }
}

export default condition
