import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

import { snakeCase } from "lodash";
import DaysWithMessagesLower from "./conditions/DaysWithMessagesLower";
import DaysWithMessagesConditionsWrapper from "./DaysWithMessagesConditionsWrapper.vue";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import store from "@/store/store";

const condition: EntityData = {
  'setupView': DaysWithMessagesConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.DaysWithMessagesConditionsWrapper,
    'currentConditionType': DaysWithMessagesLower.structure.type,
    'currentCondition': DaysWithMessagesLower.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.DaysWithMessagesConditionsWrapper) }`,
  limited: () => {
    if (store.getters.isChatSet && store.getters.isChatLicenseExists && store.getters.isChatAtLeastAdvanced) {
      return null
    } else {
      return [ {
        color: store.state.themeConfig.config.tariffs?.advanced.color,
        text: 'Advanced'
      } ]
    }
  }
}

export default condition
