


























import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";
import ActionText from "@/components/TriggerSetup/components/ActionText/ActionText.vue";
import Placeholders from "@/mixins/placeholders/placeholders";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ActionChance,
    ActionText
  }
})
export default class SendFormResultToTargetSetupView extends Mixins<ActionSetupView, Placeholders>(ActionSetupView, Placeholders) {
  get forwardTarget():string {
    return this.model.target
  }

  set forwardTarget(targetValue: string) {
    this.model.target = Number.parseInt(targetValue)
  }
}
