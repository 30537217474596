import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";

import { snakeCase } from "lodash";
import FirstUserActivityMoreThat from "./conditions/FirstUserActivityMoreThat";
import FirstUserActivityConditionsWrapper from "./FirstUserActivityConditionsWrapper.vue";

const condition: EntityData = {
  'setupView': FirstUserActivityConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.FirstUserActivityConditionsWrapper,
    'currentConditionType': FirstUserActivityMoreThat.structure.type,
    'currentCondition': FirstUserActivityMoreThat.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.FirstUserActivityConditionsWrapper) }`
}

export default condition
