import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "../../logic/types/types";
import MessageTextConditionsWrapper from "./MessageWordConditionsWrapper.vue";
import MessageWordFullMatch from "./conditions/MessageWordFullMatch";

import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': MessageTextConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.MessageWordConditionsWrapper,
    'currentConditionType': MessageWordFullMatch.structure.type,
    'currentCondition': MessageWordFullMatch.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.MessageWordConditionsWrapper) }`
}

export default condition
