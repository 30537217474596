


























import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import { conditionsWrappers } from '@/components/TriggerSetup/conditions'
import MessageCountLowerSetupView from './conditions/MessageCountLower'
import MessageCountUpperSetupView from './conditions/MessageCountUpper'
import ReplyMessageCountLowerSetupView from './conditions/ReplyMessageCountLower'
import ReplyMessageCountUpperSetupView from './conditions/ReplyMessageCountUpper'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'

@Component
export default class MessageCountConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
  get messageCountConditionOptions():Array<SelectOption> {
    return conditionsWrappers.MessageCountConditionsWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === 'MessageCountLower') {
      return MessageCountLowerSetupView
    } else if (this.conditionData.currentConditionType === 'MessageCountUpper') {
      return MessageCountUpperSetupView
    } else if (this.conditionData.currentConditionType === 'ReplyMessageCountLower') {
      return ReplyMessageCountLowerSetupView
    } else if (this.conditionData.currentConditionType === 'ReplyMessageCountUpper') {
      return ReplyMessageCountUpperSetupView
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    if (this.viewByConditionType){
      this.conditionData.currentCondition = cloneDeep(this.viewByConditionType.structure)
    }
  }
}
