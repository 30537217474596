import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { snakeCase } from "lodash";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import UnPinMessageActionSetupView from "./UnPinMessageActionSetupView.vue";

const action: EntityData = {
  'setupView': UnPinMessageActionSetupView,
  'structure': {
    'type': ActionsType.UnPinMessageAction,
    'target': "Caller",
    'chance': 100,
    'delay': 0
  },
  'titleKey': `trigger_action_${ snakeCase(ActionsType.UnPinMessageAction) }`
}

export default action
