


























import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import { conditionsWrappers } from '@/components/TriggerSetup/conditions'
import { ConditionsType } from '@/components/TriggerSetup/logic/types/types'
import UserLoginMinLen from './conditions/UserLoginMinLen'
import UserLoginMaxLen from './conditions/UserLoginMaxLen'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'

@Component
export default class UserLoginConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
  get userloginConditionOptions():Array<SelectOption> {
    return conditionsWrappers.UserLoginConditionsWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === ConditionsType.UserLoginMaxLen) {
      return UserLoginMaxLen
    } else if (this.conditionData.currentConditionType === ConditionsType.UserLoginMinLen) {
      return UserLoginMinLen
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType){
        this.conditionData.currentCondition = cloneDeep(this.viewByConditionType.structure)
      }
    })
  }
}
