


























import { conditionsWrappers } from '@/components/TriggerSetup/conditions'
import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import { ConditionsType } from '../../logic/types/types'
import ReputationEquals from './conditions/ReputationEquals'
import ReputationUpper from './conditions/ReputationUpper'
import ReputationLower from './conditions/ReputationLower'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'

@Component
export default class ReputationConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
  get reputationConditionOptions():Array<SelectOption> {
    return conditionsWrappers.ReputationConditionsWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`).toString(),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === ConditionsType.ReputationEquals) {
      return ReputationEquals
    } else if (this.conditionData.currentConditionType === ConditionsType.ReputationUpper) {
      return ReputationUpper
    } else if (this.conditionData.currentConditionType === ConditionsType.ReputationLower) {
      return ReputationLower
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType){
        this.conditionData.currentCondition = cloneDeep(this.viewByConditionType.structure)
      }
    })
  }
}
