import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import CheckPremiumConditionsWrapper from "./CheckPremiumConditionsWrapper.vue";
import CheckPremium from "./conditions/CheckPremium";

import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': CheckPremiumConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.CheckPremiumWrapper,
    'currentConditionType': CheckPremium.structure.type,
    'currentCondition': CheckPremium.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.CheckPremiumWrapper) }`,
  // limited: () => {
  //   if (!store.getters.isChatLicenseExists || !store.getters.getHaveBrand || !store.getters.getHaveLicense) {
  //     return BRAND_TAG
  //   } else {
  //     return null
  //   }
  // }
}

export default condition
