import { render, staticRenderFns } from "./ForwardReplyMessageToTargetActionSetupView.vue?vue&type=template&id=cb09745e&scoped=true&"
import script from "./ForwardReplyMessageToTargetActionSetupView.vue?vue&type=script&lang=ts&"
export * from "./ForwardReplyMessageToTargetActionSetupView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb09745e",
  null
  
)

export default component.exports