


























import { conditionsWrappers } from '@/components/TriggerSetup/conditions'
import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import { ConditionsType } from '../../logic/types/types'
import CheckPremium from "./conditions/CheckPremium";
import CheckReplyPremium from './conditions/CheckReplyPremium'
import ConditionInvertSettings
  from "../../components/ConditionInvertSettings/ConditionInvertSettings.vue";

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'

@Component({
  components: { ConditionInvertSettings }
})
export default class CheckPremiumConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
  get checkPremiumConditionOptions():Array<SelectOption> {
    return conditionsWrappers.CheckPremiumWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`).toString(),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === ConditionsType.CheckPremium) {
      return CheckPremium
    } else if (this.conditionData.currentConditionType === ConditionsType.CheckReplyPremium) {
      return CheckReplyPremium
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType) {
        this.conditionData.currentCondition = cloneDeep(this.viewByConditionType.structure)
      }
    })
  }
}
