import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { snakeCase } from "lodash";
import BanActionSetupView from "@/components/TriggerSetup/actions/BanAction/BanActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";

const action: EntityData = {
  'setupView': BanActionSetupView,
  'structure': {
    'type': ActionsType.BanAction,
    'target': "Caller",
    'time'  : 0, // Секунд
    'chance': 100,
    'targetSource': ''
  },
  'titleKey': `trigger_action_${ snakeCase(ActionsType.BanAction) }`
}

export default action
