import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "../../logic/types/types";
import MessageTextFullMatch from "./conditions/MessageTextFullMatch";
import MessageTextConditionsWrapper from "./MessageTextConditionsWrapper.vue";

import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': MessageTextConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.MessageTextConditionsWrapper,
    'currentConditionType': MessageTextFullMatch.structure.type,
    'currentCondition': MessageTextFullMatch.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.MessageTextConditionsWrapper) }`
}

export default condition
