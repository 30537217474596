import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { snakeCase } from "lodash";
import DeleteMessageActionSetupView from "@/components/TriggerSetup/actions/DeleteMessageAction/DeleteMessageActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";

const action: EntityData = {
  'setupView': DeleteMessageActionSetupView,
  'structure': {
    'type': ActionsType.DeleteMessageAction,
    'target': "Caller",
    'delay' : 0,
    'chance': 100,
  },
  'titleKey': `trigger_action_${ snakeCase(ActionsType.DeleteMessageAction) }`
}

export default action
