import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import WorkDaysSetupView
  from "@/components/TriggerSetup/conditions/WorkDays/WorkDaysSetupView.vue";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { ConditionsType, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': WorkDaysSetupView,
  'structure': {
    'type': ConditionsType.WorkDays,
    ...baseCondition,
    ...{ 'value': [ 1, 30 ] }
  } as TriggerCondition,
  'titleKey': `trigger_condition_${ snakeCase(ConditionsType.WorkDays) }`
}

export default condition
