import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { snakeCase } from "lodash";
import SilentWarnActionSetupView from "./SilentWarnActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";

const action: EntityData = {
  'setupView': SilentWarnActionSetupView,
  'structure': {
    'type': ActionsType.SilentWarnAction,
    'target': "Caller",
    'count' : 1,
    'chance': 100,
  },
  'titleKey': `trigger_action_${ snakeCase(ActionsType.SilentWarnAction) }`
}

export default action
