import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import CheckBindedChannelConditionsWrapper from "./CheckBindedChannelConditionsWrapper.vue";
import CheckBindedChannel
  from "@/components/TriggerSetup/conditions/CheckBindedChannelWrapper/conditions/CheckBindedChannel";
import store from "@/store/store";
import { BRAND_TAG } from "@/includes/constants";

import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': CheckBindedChannelConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.CheckBindedChannelWrapper,
    'currentConditionType': CheckBindedChannel.structure.type,
    'currentCondition': CheckBindedChannel.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.CheckBindedChannelWrapper) }`,
  limited: () => {
    if (!store.getters.isChatLicenseExists || !store.getters.getHaveBrand || !store.getters.getHaveLicense) {
      return BRAND_TAG
    } else {
      return null
    }
  }
}

export default condition
