import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

import { snakeCase } from "lodash";
import { ConditionsWrapperType } from "../../logic/types/types";
import MessageCountLower from "./conditions/MessageCountLower";
import MessageCountConditionsWrapper from "./MessageCountConditionsWrapper.vue";
import store from "@/store/store";

const condition: EntityData = {
  'setupView': MessageCountConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.MessageCountConditionsWrapper,
    'currentConditionType': MessageCountLower.structure.type,
    'currentCondition': MessageCountLower.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.MessageCountConditionsWrapper) }`,
  limited: () => {
    if (store.getters.isChatSet && store.getters.isChatLicenseExists && store.getters.isChatAtLeastAdvanced) {
      return null
    } else {
      return [ {
        color: store.state.themeConfig.config.tariffs?.advanced.color,
        text: 'Advanced'
      } ]
    }
  }
}

export default condition
