import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { ConditionsType, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import LastUserEnterTimeMoreThatSetupView
  from "@/components/TriggerSetup/conditions/LastUserEnterTimeConditionsWrapper/conditions/LastUserEnterTimeMoreThat/LastUserEnterTimeMoreThatSetupView.vue";
import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': LastUserEnterTimeMoreThatSetupView,
  'structure': {
    'type': ConditionsType.LastUserEnterTimeMoreThat,
    ...baseCondition,
  } as TriggerCondition,
  'titleKey': `trigger_condition_${ snakeCase(ConditionsType.LastUserEnterTimeMoreThat) }`
}

export default condition
