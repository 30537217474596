














import { Component, Mixins, Prop, Emit } from 'vue-property-decorator'
import { UseFields } from "piramis-base-components/src/components/Pi/index";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import EntitySelect from "@/components/TriggerSetup/components/EntitySelect/EntitySelect.vue";
import { conditionsGroupsTypes } from "@/components/TriggerSetup/conditions"
import { ConditionIcons } from "@/components/TriggerSetup/conditions/icons"

@Component({
  'components': {
    EntitySelect
  },
  'data': () => {
    return {
      conditionsGroupsTypes,
      ConditionIcons
    }
  }
})
export default class CreateCondition extends Mixins(UseFields) {
  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop() conditions!: Array<EntityData>

  @Emit()
  selectCondition(condition: EntityData): EntityData {
    return condition
  }
}
