import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { snakeCase } from "lodash";
import SendFormToPrivateSetupView
  from "@/components/TriggerSetup/actions/SendFormToPrivate/SendFormToPrivateSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";

const action: EntityData = {
  'setupView': SendFormToPrivateSetupView,
  'structure': {
    'type': ActionsType.SendFormToPrivate,
    'users' : [],
    'text'  : [],
    'chance': 100,
  },
  'titleKey': `trigger_action_${ snakeCase(ActionsType.SendFormToPrivate) }`
}

export default action
