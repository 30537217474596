





















import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";
import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ActionChance
  }
})
export default class ForwardMessageToTargetActionSetupView extends Mixins<ActionSetupView>(ActionSetupView) {
  get forwardTarget():string {
    return this.model.target
  }

  set forwardTarget(targetValue: string) {
    const trimValue = targetValue.trim()
    const parsed = Number.parseInt(trimValue)

    if (trimValue && !Number.isNaN(parsed)) {
      this.model.target = parsed
    } else {
      this.model.target = ''
    }
  }
}
