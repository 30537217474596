import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import BanAction from './BanAction'
import ChangeReputationIntervalAction from './ChangeReputationIntervalAction'
import ChangeTriggerActiveStateAction from './ChangeTriggerActiveStateAction'
import ChangeXpIntervalAction from './ChangeXpIntervalAction'
import DeleteMessageAction from './DeleteMessageAction'
import ForwardMessageToTargetAction from './ForwardMessageToTargetAction'
import ForwardReplyMessageToTargetAction from './ForwardReplyMessageToTargetAction'
import KickAction from './KickAction'
import MuteAction from './MuteAction'
import SendFormResultToTarget from './SendFormResultToTarget'
import SendFormToChat from './SendFormToChat'
import SendFormToPrivate from './SendFormToPrivate'
import SendMessageAction from './SendMessageAction'
import SendMessageToTargetAction from './SendMessageToTargetAction'
import SendPrivateMessageAction from './SendPrivateMessageAction'
import SetPointsAction from './SetPointsAction'
import SetReputationAction from './SetReputationAction'
import UnbanAction from './UnbanAction'
import UnmuteAction from './UnmuteAction'
import SendMessageToCalculatedTargetActionView from './SendMessageToCalculatedTargetAction'
import CalculatePropertyTriggerActionView from './CalculatePropertyTriggerAction'
import ChangePointsIntervalAction from './ChangePointsIntervalAction'
import TriggerWarnAction from './TriggerWarnAction'
import store from "@/store/store";
import WarnActionsWrapper from "./WarnActionsWrapper";
import PinMessageAction from "./PinMessageAction";
import UnPinMessageAction from "./UnPinMessageAction";
import AddMessageReactionAction from "./AddMessageReactionAction";
import { ActionsType, ActionsWrapperType } from "@/components/TriggerSetup/logic/types/types";

import Vue from "vue";
import Component from "vue-class-component";
import { chain } from "lodash";

@Component
export default class ActionsHelper extends Vue {

  get actions(): { [key: string]: EntityData } {
    return chain([
      SendMessageAction,
      DeleteMessageAction,
      KickAction,
      SendPrivateMessageAction,
      BanAction,
      UnbanAction,
      MuteAction,
      UnmuteAction,
      ChangeReputationIntervalAction,
      SetReputationAction,
      ChangePointsIntervalAction,
      SetPointsAction,
      ChangeXpIntervalAction,
      ChangeTriggerActiveStateAction,
      WarnActionsWrapper,
      TriggerWarnAction,
      PinMessageAction,
      UnPinMessageAction,
      // ULTIMATE
      SendMessageToTargetAction,
      ForwardReplyMessageToTargetAction,
      ForwardMessageToTargetAction,
      SendMessageToCalculatedTargetActionView,
      // FORMS
      SendFormToChat,
      SendFormToPrivate,
      SendFormResultToTarget,
      AddMessageReactionAction
    ])
        .keyBy('structure.type')
        .value()
  }
}

export const actionsWrappers: Record<ActionsWrapperType, Array<ActionsType>> = {
  [ActionsWrapperType.WarnActionsWrapper]: [
      ActionsType.WarnAction,
      ActionsType.SilentWarnAction
  ]
}

export const actionGroupsTypes: Record<string, Array<ActionsType | ActionsWrapperType>> = {
  'formActions': [
    ActionsType.SendFormToChat,
    ActionsType.SendFormToPrivate,
    ActionsType.SendFormResultToTarget
  ],
  'messageActions': [
    ActionsType.SendMessageAction,
    ActionsType.DeleteMessageAction,
    ActionsType.SendPrivateMessageAction,
    ActionsType.SendMessageToTargetAction,
    ActionsType.ForwardReplyMessageToTargetAction,
    ActionsType.ForwardMessageToTargetAction,
    ActionsType.SendMessageToCalculatedTargetAction,
    ActionsType.SendMessageToCalculatedTargetAction,
    ActionsType.PinMessageAction,
    ActionsType.UnPinMessageAction
  ],
  'userActions': [
    ActionsType.KickAction,
    ActionsType.BanAction,
    ActionsType.UnbanAction,
    ActionsType.MuteAction,
    ActionsType.UnmuteAction
  ],
  'userPointsActions': [
    ActionsType.ChangeReputationIntervalAction,
    ActionsType.ChangePointsIntervalAction,
    ActionsType.ChangeXpIntervalAction,
    ActionsType.SetPointsAction,
    ActionsType.SetReputationAction,
    ActionsType.CalculatePropertyTriggerAction
  ],
  'otherActions': [
    ActionsType.ChangeTriggerActiveStateAction,
      // ActionsType.TriggerWarnAction,
    ActionsWrapperType.WarnActionsWrapper,
    ActionsType.AddMessageReactionAction
  ]
}
