import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "../../logic/types/types";
import UserLoginMinLen from "./conditions/UserLoginMinLen";
import UserLoginConditionsWrapper from "./UserLoginConditionsWrapper.vue";

import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': UserLoginConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.UserLoginConditionsWrapper,
    'currentConditionType': UserLoginMinLen.structure.type,
    'currentCondition': UserLoginMinLen.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.UserLoginConditionsWrapper) }`
}

export default condition
