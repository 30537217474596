


























import { conditionsWrappers } from '@/components/TriggerSetup/conditions'
import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import { ConditionsType } from '@/components/TriggerSetup/logic/types/types'
import ReplyMessageTextFullMatch from './conditions/ReplyMessageTextFullMatch'
import ReplyMessageTextStartWith from './conditions/ReplyMessageTextStartWith'
import ReplyMessageTextEndWith from './conditions/ReplyMessageTextEndWith'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'

@Component
export default class ReplyMessageTextConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
  get replyMessageTextConditionOptions():Array<SelectOption> {
    return conditionsWrappers.ReplyMessageTextConditionsWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === ConditionsType.ReplyMessageTextFullMatch) {
      return ReplyMessageTextFullMatch
    } else if (this.conditionData.currentConditionType === ConditionsType.ReplyMessageTextStartWith) {
      return ReplyMessageTextStartWith
    } else if (this.conditionData.currentConditionType === ConditionsType.ReplyMessageTextEndWith) {
      return ReplyMessageTextEndWith
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType){
        this.conditionData.currentCondition = cloneDeep(this.viewByConditionType.structure)
      }
    })
  }
}
