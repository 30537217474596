import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "../../logic/types/types";
import ReplyMessageTextConditionsWrapper from "./ReplyMessageTextConditionsWrapper.vue";
import ReplyMessageTextFullMatch from './conditions/ReplyMessageTextFullMatch'

import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': ReplyMessageTextConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.ReplyMessageTextConditionsWrapper,
    'currentConditionType': ReplyMessageTextFullMatch.structure.type,
    'currentCondition': ReplyMessageTextFullMatch.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.ReplyMessageTextConditionsWrapper) }`
}

export default condition
