import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "../../logic/types/types";
import RankConditionsWrapper from "./RankConditionsWrapper.vue";
import RankUpper from "./conditions/RankUpper";

import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': RankConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.RankConditionsWrapper,
    'currentConditionType': RankUpper.structure.type,
    'currentCondition': RankUpper.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.RankConditionsWrapper) }`
}

export default condition
