































import { ConditionSetupView } from '@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin'
import ConditionFiltersSettings
  from '@/components/TriggerSetup/components/ConditionFiltersSettings/ConditionFiltersSettings.vue'
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'

import { Component, Mixins, Prop } from 'vue-property-decorator'
import { snakeCase } from 'lodash'

@Component({
  'components': {
    ConditionFiltersSettings,
    ConditionInvertSettings
  },
  data() {
    return {
      snakeCase,
    }
  }
})
export default class ListConditionSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {
  @Prop() type!: string
}
