import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "../../logic/types/types";
import ReplyMessageWordConditionsWrapper from "./ReplyMessageWordConditionsWrapper.vue";
import ReplyMessageWordFullMatch from './conditions/ReplyMessageWordFullMatch'

import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': ReplyMessageWordConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.ReplyMessageWordConditionsWrapper,
    'currentConditionType': ReplyMessageWordFullMatch.structure.type,
    'currentCondition': ReplyMessageWordFullMatch.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.ReplyMessageWordConditionsWrapper) }`
}

export default condition
