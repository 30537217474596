import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { snakeCase } from "lodash";
import SendMessageToTargetActionSetup
  from "@/components/TriggerSetup/actions/SendMessageToTargetAction/SendMessageToTargetActionSetup.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";

const action: EntityData = {
  'setupView': SendMessageToTargetActionSetup,
  'structure': {
    'type': ActionsType.SendMessageToTargetAction,
    'formButtonAction': "",
    'text': [ {
      "text": "",
      "buttons": [],
      "remove_after": 0,
      "send_after": 0,
      "pin": false,
      "disable_link_preview": false,
      "disable_notify": false,
      "remove_previous": false
    } ],
    'target'          : '',
    'chance'          : 100,
  },
  'titleKey': `trigger_action_${ snakeCase(ActionsType.SendMessageToTargetAction) }`
}

export default action
