import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import ReplyReputationEquals from "./conditions/ReplyReputationEquals";
import ReplyReputationConditionsWrapper from "./ReplyReputationConditionsWrapper.vue";

import { snakeCase } from "lodash";
import store from "@/store/store";

const condition: EntityData = {
  'setupView': ReplyReputationConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.ReplyReputationConditionsWrapper,
    'currentConditionType': ReplyReputationEquals.structure.type,
    'currentCondition': ReplyReputationEquals.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.ReplyReputationConditionsWrapper) }`,
  limited: () => {
    if (store.getters.isChatSet && store.getters.isChatLicenseExists && store.getters.isChatAtLeastAdvanced) {
      return null
    } else {
      return [ {
        color: store.state.themeConfig.config.tariffs?.advanced.color,
        text: 'Advanced'
      } ]
    }
  }
}

export default condition
