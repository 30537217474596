import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { snakeCase } from "lodash";
import KickActionSetupView from "@/components/TriggerSetup/actions/KickAction/KickActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";

const action: EntityData = {
  'setupView': KickActionSetupView,
  'structure': {
    'type': ActionsType.KickAction,
    'target': "Caller",
    'chance': 100,
    'targetSource': ''
  },
  'titleKey': `trigger_action_${ snakeCase(ActionsType.KickAction) }`
}

export default action
