


























import { conditionsWrappers } from '@/components/TriggerSetup/conditions'
import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import { ConditionsType } from '@/components/TriggerSetup/logic/types/types'
import MessageWordFullMatch from './conditions/MessageWordFullMatch'
import MessageWordEndWith from './conditions/MessageWordEndWith'
import MessageWordStartWith from './conditions/MessageWordStartWith'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'

@Component
export default class MessageWordConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
  get messageWordConditionOptions():Array<SelectOption> {
    return conditionsWrappers.MessageWordConditionsWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === ConditionsType.MessageWordFullMatch) {
      return MessageWordFullMatch
    } else if (this.conditionData.currentConditionType === ConditionsType.MessageWordStartWith) {
      return MessageWordStartWith
    } else if (this.conditionData.currentConditionType === ConditionsType.MessageWordEndWith) {
      return MessageWordEndWith
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType){
        this.conditionData.currentCondition = cloneDeep(this.viewByConditionType.structure)
      }
    })
  }
}
