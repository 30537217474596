import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import ListConditionSetupView
  from "@/components/TriggerSetup/components/ListConditionSetupView/ListConditionSetupView.vue";
import { ConditionsType, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': ListConditionSetupView,
  'structure': {
    'type': ConditionsType.ReplyMessageWordEndWith,
    ...baseCondition,
  } as TriggerCondition,
  'titleKey': `trigger_condition_${ snakeCase(ConditionsType.ReplyMessageWordEndWith) }`
}

export default condition
