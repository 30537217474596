





















import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import AdvancedConditionSettings
  from "@/components/TriggerSetup/components/AdvancedConditionSettings/AdvancedConditionSettings.vue";
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'

import { Component, Mixins } from 'vue-property-decorator'
import { snakeCase } from 'lodash'

@Component({
  'components': {
    AdvancedConditionSettings,
    ConditionInvertSettings
  }
})
export default class NumberConditionSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {
  get numberValue(): number {
    return this.conditionData.value[0] || 0
  }

  set numberValue(value: number) {
    this.$set(this.conditionData, 'value', [ value ])
  }

  get triggerPrefix():string {
    return `trigger_condition_${ snakeCase(this.conditionData.type) }_`
  }
}
