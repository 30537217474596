import { render, staticRenderFns } from "./SendMessageActionSetupView.vue?vue&type=template&id=1cee2bd2&scoped=true&"
import script from "./SendMessageActionSetupView.vue?vue&type=script&lang=ts&"
export * from "./SendMessageActionSetupView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cee2bd2",
  null
  
)

export default component.exports