import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

import { snakeCase } from "lodash";
import { ConditionsWrapperType } from "../../logic/types/types";
import UserInformation from "./conditions/UserInformation";
import RegexpConditionsWrapper from "./UserInformationConditionsWrapper.vue";

const condition: EntityData = {
  'setupView': RegexpConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.UserInformationWrapper,
    'currentConditionType': UserInformation.structure.type,
    'currentCondition': UserInformation.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.UserInformationWrapper) }`
}

export default condition
