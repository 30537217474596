


























import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import XpEqualsSetupView from './conditions/XpEquals'
import XpLowerSetupView from './conditions/XpLower'
import XpUpperSetupView from './conditions/XpUpper'
import ReplyXpUpperSetupView from './conditions/ReplyXpUpper'
import ReplyXpLowerSetupView from './conditions/ReplyXpLower'
import ReplyXpEqualsSetupView from './conditions/ReplyXpEquals'
import { conditionsWrappers } from '@/components/TriggerSetup/conditions'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'

@Component
export default class XpConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
  get xpConditionOptions():Array<SelectOption> {
    return conditionsWrappers.XpConditionsWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === 'XpEquals') {
      return XpEqualsSetupView
    } else if (this.conditionData.currentConditionType === 'XpLower') {
      return XpLowerSetupView
    } else if (this.conditionData.currentConditionType === 'XpUpper') {
      return XpUpperSetupView
    } else if (this.conditionData.currentConditionType === 'ReplyXpEquals') {
      return ReplyXpEqualsSetupView
    } else if (this.conditionData.currentConditionType === 'ReplyXpLower') {
      return ReplyXpLowerSetupView
    } else if (this.conditionData.currentConditionType === 'ReplyXpUpper') {
      return ReplyXpUpperSetupView
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType){
        this.conditionData.currentCondition = cloneDeep(this.viewByConditionType.structure)
      }
    })
  }
}
