




































import { TriggerHelper } from '@/components/TriggerSetup/logic/mixins/TriggerSetupMixin'
import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'

import DefaultList from 'piramis-base-components/src/components/Pi/components/DefaultList/DefaultList.vue'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'
import { Entity, Item, ItemIcon } from 'piramis-base-components/src/components/Pi/components/DefaultList/logic/types'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
import { snakeCase } from 'lodash'
import { Guid } from 'guid-typescript'

@Component({
  components: {
    DefaultList,
    Tags,
    ConfigField
  },
  data() {
    return {
      ListDirection,
    }
  },
  methods: {
    snakeCase
  }
})
export default class EntitySelect extends Mixins(UseFields, TriggerHelper) {
  @Prop({ 'required': true }) readonly entitiesConfig!: { entities: Array<EntityData>, groupsTypes: { [key: string]: Array<string> }, entitiesIcons: { [key: string]: ItemIcon } }

  @Prop({ 'type': String, 'required': true, }) readonly buttonTitle!: string

  @Prop({ 'type': String, 'required': true, }) readonly popupTitleKey!: string

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Emit()
  select(value: Item): EntityData {
    this.isPopupOpen = false
    return this.entitiesConfig.entities.find(entity => entity.titleKey === value.key) as EntityData
  }

  isPopupOpen = false

  get icons() {
    return this.entitiesConfig.entitiesIcons
  }

  get groupsTypes(): { [key: string]: Array<string> } {
    return this.entitiesConfig.groupsTypes
  }

  getConfig(list: Array<EntityData>): Array<Entity> {
    const filteredEntitiesList: Array<Entity> = []

    list.forEach(listItem => {
      let groupTitle: string | null = ''

      const itemTitle = this.$t(listItem.titleKey).toString()
      const itemLimited = typeof listItem.limited === 'function' ? listItem.limited() : listItem.limited
      const itemIcon = this.icons[listItem.structure.type]

      const scType = snakeCase(listItem.structure.type)
      const actionTranslationKey = `trigger_action_${ scType }_help_message`
      const conditionTranslationKey = `trigger_condition_${ scType }_help_message`

      Object.entries(this.groupsTypes).forEach(entre => {
        if (entre[1].includes(listItem.structure.type)) {
          groupTitle = `trigger_group_${ snakeCase(entre[0]) }`
        }
      })

      let helpMessage = ''

      if (this.$te(actionTranslationKey)) {
        helpMessage = this.$t(actionTranslationKey).toString()
      }

      if (this.$te(conditionTranslationKey)) {
        helpMessage = this.$t(conditionTranslationKey).toString()
      }

      if (filteredEntitiesList.some(filteredItem => filteredItem.group && filteredItem.group.titleKey === groupTitle)) {
        const findItem: Entity | undefined = filteredEntitiesList.find(filteredItem => filteredItem.group && filteredItem.group.titleKey === groupTitle)

        if (findItem) {
          (findItem.group?.groupItems as Array<Item>).push({
            title: itemTitle,
            limited: itemLimited,
            helpMessage: helpMessage,
            icon: itemIcon,
            key: listItem.titleKey,
          })
        }
      } else {

        filteredEntitiesList.push({
          group: {
            titleKey: groupTitle,
            guid: Guid.create().toString(),
            groupItems: [ {
              title: itemTitle,
              limited: itemLimited,
              helpMessage: helpMessage,
              icon: itemIcon,
              key: listItem.titleKey
            } ],
          }
        })
      }
    })

    return filteredEntitiesList
  }
}
