import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import LastUserEnterTimeMoreThat from "./conditions/LastUserEnterTimeMoreThat";
import LastUserEnterTimeConditionsWrapper from "./LastUserEnterTimeConditionsWrapper.vue";

import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': LastUserEnterTimeConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.LastUserEnterTimeConditionsWrapper,
    'currentConditionType': LastUserEnterTimeMoreThat.structure.type,
    'currentCondition': LastUserEnterTimeMoreThat.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.LastUserEnterTimeConditionsWrapper) }`
}

export default condition
