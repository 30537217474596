import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import WarnActionsWrapper from "./WarnActionsWrapper.vue";
import WarnActionSetupView from "../WarnAction";
import { ActionsWrapperType } from "@/components/TriggerSetup/logic/types/types";

import { snakeCase } from "lodash";

const action: EntityData = {
  'setupView': WarnActionsWrapper,
  'structure': {
    'type': ActionsWrapperType.WarnActionsWrapper,
    'sendWarnMessage': '',
    'currentAction': WarnActionSetupView.structure
  },
  'titleKey': `trigger_action_${ snakeCase(ActionsWrapperType.WarnActionsWrapper) }`
}

export default action
