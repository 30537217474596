import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";

import { snakeCase } from "lodash";
import FirstUserMessageTimeConditionsWrapper from "./FirstUserMessageTimeConditionsWrapper.vue";
import FirstUserMessageTimeMoreThat from "./conditions/FirstUserMessageTimeMoreThat";

const condition: EntityData = {
  'setupView': FirstUserMessageTimeConditionsWrapper,
  'structure': {
    'type': ConditionsWrapperType.FirstUserMessageTimeConditionsWrapper,
    'currentConditionType': FirstUserMessageTimeMoreThat.structure.type,
    'currentCondition': FirstUserMessageTimeMoreThat.structure
  },
  'titleKey': `trigger_condition_${ snakeCase(ConditionsWrapperType.FirstUserMessageTimeConditionsWrapper) }`
}

export default condition
