


























import { conditionsWrappers } from '@/components/TriggerSetup/conditions'
import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import { ConditionsType } from '../../logic/types/types'
import LastUserEnterTimeLowerThat from './conditions/LastUserEnterTimeLowerThat'
import LastUserEnterTimeMoreThat from './conditions/LastUserEnterTimeMoreThat'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins } from 'vue-property-decorator'
import { snakeCase } from 'lodash'

@Component
export default class LastUserEnterTimeConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
  get lastUserEnterTimeConditionOptions():Array<SelectOption> {
    return conditionsWrappers.LastUserEnterTimeConditionsWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`).toString(),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === ConditionsType.LastUserEnterTimeMoreThat) {
      return LastUserEnterTimeMoreThat
    } else if (this.conditionData.currentConditionType === ConditionsType.LastUserEnterTimeLowerThat) {
      return LastUserEnterTimeLowerThat
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType){
        this.conditionData.currentCondition = this.viewByConditionType.structure
      }
    })
  }
}
