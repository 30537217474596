



























import { conditionsWrappers } from '@/components/TriggerSetup/conditions'
import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import { ConditionsType } from '../../logic/types/types'
import CheckReplyBoost from "./conditions/CheckReplyBoost";
import CheckBoost from './conditions/CheckBoost'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'

@Component
export default class CheckBoostConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
  get checkBoostConditionOptions():Array<SelectOption> {
    return conditionsWrappers.CheckBoostWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`).toString(),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === ConditionsType.CheckBoost) {
      return CheckBoost
    } else if (this.conditionData.currentConditionType === ConditionsType.CheckReplyBoost) {
      return CheckReplyBoost
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType) {
        this.conditionData.currentCondition = cloneDeep(this.viewByConditionType.structure)
      }
    })
  }
}
