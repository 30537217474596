import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import ConditionRank from '@/components/TriggerSetup/components/ConditionRank/ConditionRank.vue'
import { ConditionsType, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { snakeCase } from "lodash";

const condition: EntityData = {
  'setupView': ConditionRank,
  'structure': {
    'type': ConditionsType.RankEquals,
    ...baseCondition,
    ...{ 'value': [ 0 ] }
  } as TriggerCondition,
  'titleKey': `trigger_condition_${ snakeCase(ConditionsType.RankEquals) }`
}

export default condition
