import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { snakeCase } from "lodash";
import SendPrivateMessageActionSetupView
  from "@/components/TriggerSetup/actions/SendPrivateMessageAction/SendPrivateMessageActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";

const action: EntityData = {
  'setupView': SendPrivateMessageActionSetupView,
  'structure': {
    'type': ActionsType.SendPrivateMessageAction,
    'formButtonAction': "",
    'text': [ {
      "text": "",
      "buttons": [],
      "remove_after": 0,
      "send_after": 0,
      "pin": false,
      "disable_link_preview": false,
      "disable_notify": false,
      "remove_previous": false
    } ],
    'users' : [],
    'chance': 100,
  },
  'titleKey': `trigger_action_${ snakeCase(ActionsType.SendPrivateMessageAction) }`
}

export default action
