


























import { conditionsWrappers } from '@/components/TriggerSetup/conditions'
import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import DaysWithMessagesLower from './conditions/DaysWithMessagesLower'
import DaysWithMessagesUpper from './conditions/DaysWithMessagesUpper'
import ReplyDaysWithMessagesLower from './conditions/ReplyDaysWithMessagesLower'
import ReplyDaysWithMessagesUpper from './conditions/ReplyDaysWithMessagesUpper'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'

@Component
export default class DaysWithMessagesConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
  get daysWithMessagesConditionOptions():Array<SelectOption> {
    return conditionsWrappers.DaysWithMessagesConditionsWrapper.map(type => ({
      label: this.$t(`trigger_condition_${ snakeCase(type) }`).toString(),
      value: type
    }))
  }

  get viewByConditionType() {
    if (this.conditionData.currentConditionType === 'DaysWithMessagesLower') {
      return DaysWithMessagesLower
    } else if (this.conditionData.currentConditionType === 'DaysWithMessagesUpper') {
      return DaysWithMessagesUpper
    } else if (this.conditionData.currentConditionType === 'ReplyDaysWithMessagesLower') {
      return ReplyDaysWithMessagesLower
    } else if (this.conditionData.currentConditionType === 'ReplyDaysWithMessagesUpper') {
      return ReplyDaysWithMessagesUpper
    } else {
      throw (`Unknown condition type, got: ${ this.conditionData.currentConditionType }`)
    }
  }

  onCurrentConditionTypeChange():void {
    this.$nextTick(() => {
      if (this.viewByConditionType){
        this.conditionData.currentCondition = cloneDeep(this.viewByConditionType.structure)
      }
    })
  }
}
